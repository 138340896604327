// https://stackoverflow.com/questions/29001977/safari-in-ios8-is-scrolling-screen-when-fixed-elements-get-focus
.hack-show-safari-bars {
  &__html, &__body {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto;
    height: 100%;
  }

  &__body {
    position: relative;
    overflow-x: hidden;
    min-height: 100%;
  }
}

// IE Hacks go here!
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // no scrolling if parent has max-height set
  .modal-content {
    max-height: 100% !important;
  }
}

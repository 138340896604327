.fade.show {
  opacity: 1;
}

.app-modal__backdrop.show {
  opacity: 0.6;
}

// Used by Google Ad Manager
.white-popup {
  padding: 35px 30px;
}

.home {
  h1 {
    @include title-xlarge;
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 25px;
  }

  h2 {
    @include title-xlarge;
    margin-bottom: 15px;

    &.home__input-title {
      color: $color-black;
    }

    @media ($under-screen-medium) {
      font-size: $font-size-m;
      line-height: $font-line-height-m;
    }
  }

  h3 {
    @include title-medium;
    margin-top: 20px;
  }

  &__header {
    background-color: $color-black;

    p, form {
      margin-bottom: 15px;
    }
  }

  .form-control + button {
    padding: 13px 20px 14px;

    @media ($under-screen-small) {
      padding: 6px 12px 7px;
    }
  }

  &__background {
    position: relative;
  }

  &__banner {
    position: relative;
    z-index: 1000;
    padding: 90px 15px;
    max-width: 1200px;
    margin: 0 auto;

    @media ($over-screen-xlarge) {
      padding: 130px 15px;
    }

    @media ($under-screen-medium) {
      padding: 70px 15px;
    }

    @media ($under-screen-small) {
      padding: 40px 15px;
      line-height: 24px;
    }
  }

  &__background-content {
    max-width: 602px;
    color: $color-white;

    h1, a {
      color: $color-white;
    }

    a:hover {
      text-decoration: underline;
    }

    p {
      font-size: $font-size-m;
    }
  }

  &__mobile-text {
    @include title-medium;
    color: $color-white;

    @media ($over-screen-small) {
      display: none;
    }
  }

  &__presslist {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
  }

  &__press {
    display: flex;
    flex-wrap: wrap;
    max-width: 190px;

    &__image {
      padding: 20px;
      width: 100%;
    }
  }

  &__input-title {
    margin-bottom: 15px;
    margin-right: 30px;
    color: $color-white;

    @media ($under-screen-medium) {
      font-size: $font-size-m;
      line-height: $font-line-height-m;
    }
  }

  &__search-word {
    min-width: 380px;
    display: inline-block;

    @media ($under-screen-medium) {
      min-width: 220px;
    }
  }

  &__partners, &__video {
    img {
      width: 100%;
    }
  }

  &__common-links {
    margin-top: 50px;
    margin-bottom: 20px;

    @media ($under-screen-small) {
      margin-top: 0;
    }
  }

  &__link-section {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;

    @media ($over-screen-small) {
      height: 210px;
    }

    @media ($over-screen-medium) {
      height: 135px;
    }

    a {
      @include text-link-primary;
      line-height: $font-line-height-m;

      @media ($over-screen-small) {
        width: 33%;
      }
    }

    &--large {
      @media ($over-screen-small) {
        height: 300px;
      }
    }

    &--small {
      @media ($over-screen-small) {
        height: 500px;
      }
    }
  }

  &__link-more {
    margin-top: 20px;
  }
}

.sidebar-container {
  flex: 1;
  margin: 0 7.5px;

  @media ($under-screen-small) {
    margin: 15px;
  }
}

.sidebar__card-title {
  @include title-medium;
}

.sidebar__list {
  list-style:  none;
  margin: 12px 0 0 0;
  padding: 0;
}

.needs-assessment {
  &__category {
    background-color: $color-blue-background;
    margin: 0 0 20px -30px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 10px 15px 10px 30px;
    display: inline;
    font-size: 13px;
  }

  &__title {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $color-text-link-secondary;
    margin: 30px 0;
  }

  &__benefits {
    margin: 20px 0;
    .icon {
      color: $color-green;
      margin-right: 5px;
    }
  }

  &__past-results {
    margin: 20px 0;

    &-btn {
      @media ($under-screen-small) {
        width: 100%;
      }
    }

    &-btn-subtitle {
      color: $color-grey-dark;
      font-size: 13px;
      margin: 10px 0;
    }

    &-title {
      @include title-medium;
      border-bottom: $grey-line;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    &-item {
      border-bottom: $grey-line;
      padding: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.needs-assessment-list {
  &__container {
    margin-top: 20px;
    margin-bottom: 100px;
    display: flex;

    @media ($under-screen-medium) {
      flex-direction: column;
      padding: 0 15px;
    }
  }

  &__body {
    flex: 2;
    padding-right: 100px;

    @media ($under-screen-medium) {
      flex: 1;
      padding-right: 0;
    }
  }

  &__sidebar {
    flex: 1;

    @media ($under-screen-medium) {
      margin-top: 20px;
    }
  }
}

.needs-assessment-results {
  &__panel {
    margin-top: 20px;
    margin-bottom: 100px;

    @media ($under-screen-small) {
      padding: 0 15px;
      margin-bottom: 40px;
    }
  }

  &__title-wrapper {
    border-bottom: $grey-line;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }

  &__main-title {
    @include title-large;
    display: inline;
    padding: 0 0 15px;

    &__link {
      display: inline-block;
      margin-left: 20px;
      @include text-link-primary;

      @media ($under-screen-small) {
        display: none;
      }
    }
  }

  &__feedback {
    display: inline-block;
    margin-top: 15px;
    @media ($over-screen-small) {
      display: none;
    }
  }

  &__breadcrumbs {
    @include text-small;

    a {
      @include text-small;
      color: $color-text-light;
    }
  }

  &__card-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__section-title {
    @include title-medium;
    margin-top: 20px;
  }

  &__more-link {
    @include text-link-primary;
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    .icon {
      font-size: 8px;
      margin-left: 7px;
    }
  }

  &__top-need {
    @include title-medium;
    margin: 25px 0 0;

    @media ($under-screen-small) {
      margin: 15px 0 0;
    }
  }

  &__subtitle {
    @include title-medium;
    border-bottom: $grey-line;
    margin: 25px 0 20px;
    padding-bottom: 10px;
  }

  &__description {
    @include text-light;
    margin: 5px 0 10px;
  }

  &__notice {
    margin: 20px 0 0;
  }

  &__filter {
    margin: 20px 0;

    &-label {
      margin: 0 0 10px;
    }

    &-dropdown {
      width: 300px;
      @media ($under-screen-small) {
        width: 100%;
      }

      select.form-control {
        width: 100%;
      }

      span.icon {
        color: $color-text-link-secondary;
        top: -4px;
        right: 17px;
      }
    }
  }

  &__category {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 10px;
  }

  &__info {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $color-blue;
    border: 2px solid $color-blue;
    cursor: pointer;
    margin-left: 5px;
    font-size: $font-size-xs;
  }

  &__need {
    @include title-small;
    display: flex;
    border-bottom: $grey-line;
    padding: 10px 0;
    justify-content: space-between;

    @media ($over-screen-small) {
      padding: 10px 20px;
    }

    &:last-of-type {
      margin-bottom: 15px;
    }

    div:first-of-type {
      flex: 1;
    }

    &--common-need {
      flex-direction: column;
    }
  }

  &__need-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 20px;
  }

  &__need-answer {
    @include text;
    padding: 0 0 0 10px;
  }

  &__need-percentage {
    font-weight: bold;
    margin: 0 10px;
    display: inline-block;
  }

  &__card-more {
    background-color: $color-blue-background;
    box-shadow: none;
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    &-center {
      max-width: 50%;
    }

    &-title {
      @include title-medium;
    }

    &-body {
      @include text;
      margin: 5px 0 20px;
      max-width: 300px;
    }
  }

  &__tips {
    background-color: $color-blue-background;
    margin: 10px 0;
    padding: 20px;
    @include text;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ($under-screen-small) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-title {
      @include title-medium;
      margin: 0 0 10px;
    }

    &--small {
      font-size: 13px;
    }
  }

  &__question-row {
    margin-top: 15px;
  }
}

// TRANSITIONS

.TRANSITION-simpleFade-enter {
  opacity: 0.01;
  transition: opacity .5s ease-in;
  &.TRANSITION-simpleFade-enter-active {
    opacity: 1;
  }
}

.TRANSITION-simpleFade-exit {
  opacity: 1;
  transition: opacity .3s ease-out;
  &.TRANSITION-simpleFade-exit-active {
    opacity: 0.01;
  }
}

.TRANSITION-slide-enter {
  opacity: 0.01;
  transition: all .75s ease-in;
  transform: translateY(-10px);

  &.TRANSITION-slide-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.TRANSITION-slide-exit {
  opacity: 1;
  transition: all .75ms ease-in;
  transform: translateY(10);

  &.TRANSITION-slide-exit-active {
    opacity: 0.01;
    transform: translateY(80px);
  }
}

.TRANSITION-line-slide-enter {
  transition: all .75ms ease-in;
  transform: translateX(10);

  &.TRANSITION-line-slide-active {
    transform: translateX(10px);
  }
}

.TRANSITION-line-slide-exit {
  transition: all .75ms ease-in;
  transform: translateX(10);

  &.TRANSITION-line-slide-exit-active {
    transform: translateX(10px);
  }
}

.TRANSITION-slideUp-enter {
  opacity: 0.01;
  transition: all .5s ease-in;
  transform: translateY(30px);

  &.TRANSITION-slideUp-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
}

.TRANSITION-slideUp-exit {
  opacity: 1;
  transition: all .3ms ease-in;
  transform: translateY(-30);

  &.TRANSITION-slideUp-exit-active {
    opacity: 0.01;
    transform: translateY(-80px);
  }
}

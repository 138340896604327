/* Include parts of Bootstrap */

// 1. Functions come first (required)
@import "~bootstrap/scss/functions";

// 2. Default variables overrides

// Brand Colors
$primary: $color-blue;
$green: $color-green;
$white: $color-white;

// Headings
$headings-margin-bottom: 0.83em;

// Paragraphs
$paragraph-margin-bottom: 0;

// Links
$link-decoration: none;
$link-hover-decoration: none;

// reactstrap dropdown
$dropdown-link-hover-bg: $color-grey-light;
$dropdown-spacer: 4px;
$dropdown-border-color: $color-grey;
$dropdown-item-padding-x: 1.1rem;

// 3. Variables come next (required)
@import "~bootstrap/scss/variables";

// 4. Default map overrides
$theme-colors: (primary: $color-blue, secondary: $white);

// 5. Rest of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// Custom
@import "~bootstrap/scss/reboot"; // normalization
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown"; // reactstrap
@import "~bootstrap/scss/button-group"; // reactstrap
@import "~bootstrap/scss/modal"; // reactstrap
@import "~bootstrap/scss/carousel"; // reactstrap
@import "~bootstrap/scss/tooltip"; // reactstrap

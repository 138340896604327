.default-page-container {
  padding: 15px;
  margin: $nav-height-top auto 0;
  max-width: 1200px;
  width: 100vw;

  @media ($under-screen-small) {
    padding: 0;
  }
}

.full-width-page-container {
  min-height: 300px;
}

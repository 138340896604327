.community__home-empty {
  margin: 30px 0;

  @media ($under-screen-small) {
    margin: 0;
    padding: 30px 15px;
    border-top: $grey-line;
  }
}

.community__home-filter {
  @media ($under-screen-small) {
    margin: 15px;
  }
  .dropdown-toggle {
    @include title-small;
    padding: 0;
  }
  .dropdown-menu {
    line-height: 35px;
  }
}

.community__home-pinned-button {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: $grey-line;
  @include title-small;
  .icon {
    @include text-link-primary;
  }
  @media ($over-screen-small) {
    display: none;
    padding: 0 15px;
  }
}
